import { defineStore } from 'pinia'
import {useCookieStore} from "~/store/cookie";
import {checkCache, transform} from "~/utils/cache";

export const useAudioStore = defineStore({
    id: 'audio-store',
    state: () => {
        return {
          audio: null,
          filesPage: null,
          fetchError: false,
          invalidFileTypeError: false,
          fileTooLargeError: false,
          noAudioStreamError: false,
          notFoundError: false,
          categoriesPage: null,
          uploadData: null,
          uploadCategory: 1,
          number_of_speakers: 0,
          language_id: null,
          transcribe: true,
          languages: null,
          removeFillerWords: false,
          downloadError: false,
          backgroundAudioList: null,
          backgroundAudio: null,
          mainAudioVolume: 100,
          backgroundAudioVolume: 100,
          noVideoStreamError: false,
          backgroundVideoList: null,
        }
    },
    getters: {
      recentAudioFiles: (state) => {
        if (state.filesPage && state.filesPage.results) {
          // get the first 4 clips
          return state.filesPage.results.slice(0, 4);
        }
      }
    },
    actions: {
        reset() {
          // Reset parameters for next time
          this.transcribe = true;
          this.number_of_speakers = 0;
          this.uploadCategory = 1;
          this.language_id = 1;
        },
        async createAudio(audio, audioName, config, $sentryCaptureException) {
          this.fetchError = false;
          this.invalidFileTypeError = false;
          this.fileTooLargeError = false;
          this.noAudioStreamError = false;

          let formData = new FormData();
          formData.append('name', audioName);
          formData.append('files', audio);

          const cookieStore = useCookieStore();
          const resp = await $fetch(config.public.externalApiBase +`/api/upload/`, {
              method: 'PUT',
              server: false,
              body: formData,
              params: {name: audioName, transcribe: Boolean(this.transcribe), category: this.uploadCategory,
                number_of_speakers: this.number_of_speakers, language_id: this.language_id, remove_filler_words: this.removeFillerWords},
              headers: { 'Authorization': 'Token ' + cookieStore.token}
          }).catch((error) => {
            if (!error.response) {
              this.fetchError = true;
              return;
            }

            if (error.response.status === 415) {
              this.invalidFileTypeError = true;
            }
            else if (error.response.status === 413) {
              this.fileTooLargeError = true;
            }
            else if (error.response._data && error.response._data.error === 'no.audio.stream') {
              this.noAudioStreamError = true;
            }
            else {
              this.fetchError = true;
            }
            console.log(error.response);
            if ($sentryCaptureException) {
              $sentryCaptureException(error.response.message);
            }

          })

          this.uploadData = resp;
          if (resp && 'remove_filler_words' in resp) {
            this.removeFillerWords = resp.remove_filler_words;
          }
        },
        async updateAudioName(key, audioName, config) {
          const cookieStore = useCookieStore();
          const resp = await $fetch(config.public.externalApiBase +`/api/audio/${key}/`, {
              method: 'PATCH',
              server: false,
              body: {original_name: audioName},
              headers: { 'Authorization': 'Token ' + cookieStore.token}
          }).catch((error) => {
              console.log(error);
              this.fetchError = true;
          })
        },
        async getAudio(key, config, backgroundAudio=false) {
            const cookieStore = useCookieStore();
            const {data, error} = await useFetch(config.public.externalApiBase +`/api/audio/${key}/`, {
                method: 'GET',
                headers: { 'Authorization': 'Token ' + cookieStore.token}
            }).catch((error) => {
                this.fetchError = true;
            });

            if (data.value) {
              this.fetchError = false;
              this.notFoundError = false;
              if (backgroundAudio) {
                this.backgroundAudio = data.value;
              }
              else {
                this.audio = data.value;
              }
            }

            if (error.value) {
              if (error.value.statusCode === 404) {
                this.notFoundError = true;
              }
              else {
                this.fetchError = true;
              }
            }
        },
        async getPresignedUrl(key, config, download=false) {
            const params = {}
            if (download) {
              params['download'] = 'true';
            }
            const cookieStore = useCookieStore();
            const {data, error} = await useFetch(config.public.externalApiBase +`/api/audio/${key}/presigned_url/`, {
                method: 'GET',
                headers: { 'Authorization': 'Token ' + cookieStore.token},
                params: params
            }).catch((error) => {
                this.fetchError = true;
            });

            if (data.value) {
              this.fetchError = false;
              this.notFoundError = false;
              return data.value.url;
            }

            if (error.value) {
              if (error.value.statusCode === 404) {
                this.notFoundError = true;
              }
              else {
                this.fetchError = true;
              }
            }
        },
        async getCategories(config) {
          const cookieStore = useCookieStore();
          const {data, error} = await useFetch(config.public.externalApiBase +`/api/uploadcategories/`, {
              method: 'GET',
              transform: transform,
              getCachedData(key) {
                return checkCache(key, 1000 * 60 * 60);
              }
          }).catch((error) => {
              console.log(error);
              this.fetchError = true;
          });

          if (data.value) {
             this.categoriesPage = data.value;
          }
        },
        async deleteAudioFile(key, config) {
          const cookieStore = useCookieStore();
          const {data, error} = await useFetch(config.public.externalApiBase +`/api/audio/${key}/`, {
              method: 'DELETE',
              headers: { 'Authorization': 'Token ' + cookieStore.token}
          }).catch((error) => {
              console.log(error);
              this.fetchError = true;
          });

          if (error.value) {
            this.fetchError = true;
          }
        },
        async getAudioFiles(page, config, upload_type='audio') {
          let params = {
            page: page
          }
          params['upload_type'] = upload_type;
          this.fetchError = false;

          const cookieStore = useCookieStore();
          const resp = await $fetch(config.public.externalApiBase + `/api/audio-uploads/`, {
            method: 'GET',
            headers: {'Authorization': 'Token ' + cookieStore.token},
            params: params,
          }).catch((error) => {
            this.fetchError = true;
          });

          if (resp) {
            this.filesPage = resp;
          }
        },
        async getLanguages(config) {
          this.fetchError = false;
          const {data, error} =
                await useFetch( `${config.public.externalApiBase}/api/transcription-languages/`, {
              method: 'GET',
              transform:transform,
              getCachedData(key) {
                return checkCache(key, 1000 * 60 * 60);
              }
          }).catch((error) => {
              this.fetchError = true;
          });

          if (data.value) {
            this.languages = data.value.results;
          }
          if (error.value) {
            this.fetchError = true;
          }
        },
        async uploadOtherType(upload_file, upload_name, upload_type, config) {
          this.fetchError = false;
          this.invalidFileTypeError = false;
          this.fileTooLargeError = false;
          this.noVideoStreamError = false;

          let formData = new FormData();
          formData.append('name', upload_name);
          formData.append('files', upload_file);

          const cookieStore = useCookieStore();
          const resp = await $fetch(config.public.externalApiBase +`/api/upload/`, {
              method: 'PUT',
              server: false,
              body: formData,
              params: {name: upload_name, upload_type: upload_type},
              headers: { 'Authorization': 'Token ' + cookieStore.token}
          }).catch((error) => {
            if (!error.response) {
              this.fetchError = true;
              return;
            }

            if (error.response.status === 415) {
              this.invalidFileTypeError = true;
            }
            else if (error.response.status === 413) {
              this.fileTooLargeError = true;
            }
            else if (error.response._data && error.response._data.error === 'video.invalid') {
              this.noVideoStreamError = true;
            }
            else {
              this.fetchError = true;
            }
            console.log(error);
          })

          this.uploadData = resp;

        },
    }
});